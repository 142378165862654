import { CardHeader, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import { Container } from './styles';

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

export default function ItemNew(params) {
  const [form] = Form.useForm();

  const [updateFlag, setUpdateFlag] = useState(false);
  const [query, setQuery] = useState();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [storages, setStorages] = useState([]);
  const [cpus, setCpus] = useState([]);
  const [rams, setRams] = useState([]);
  const [operatingSystems, setOperatingSystems] = useState([]);
  const [keyboards, setKeyboards] = useState([]);
  const [screenSizes, setScreenSizes] = useState([]);
  const [screenTypes, setScreenTypes] = useState([]);
  const [screenResolutions, setScreenResolutions] = useState([]);
  const [screenTechnologies, setScreenTechnologies] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [itemInfo, setItemInfo] = useState({});
  const profile = useSelector((state) => state.user.profile);
  const [batteryRequired, setBatteryRequired] = useState(false);
  const [identifierList, setIdentifierList] = useState([]);

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const checkboxOptions = [
    {
      label: 'Internal',
      value: 'internal',
    },
    {
      label: 'Removable',
      value: 'removable',
    },
    {
      label: 'Removable2',
      value: 'removable2',
    },
  ];

  useEffect(() => {
    async function loadDropdownboxes() {
      const location = new URLSearchParams(params.location.search);
      const item_id = location.get('id');
      const searchQuery = location.get('searchQuery');
      const categoriesList = await api.get('categories');
      const subcategoriesList = await api.get('subcategories');
      const brandsList = await api.get('brands');
      const manufacturersList = await api.get('manufacturers');
      const storagesList = await api.get('storages');
      const cpusList = await api.get('cpus');
      const ramsList = await api.get('rams');
      const operatingSystemsList = await api.get('operating_systems');
      const keyboardsList = await api.get('keyboard_types');
      const screenSizesList = await api.get('screen_sizes');
      const screenTypesList = await api.get('screen_types');
      const screenResolutionsList = await api.get('screen_resolutions');
      const screenTechnologiesList = await api.get('screen_technologies');

      if (item_id !== undefined && item_id !== null) {
        const item_info = await api.get(`items?id=${item_id}`);

        setBatteryRequired(!!item_info.data[0].battery_type || [1, 2, 3].includes(item_info.data[0].category_id));

        const item = {
          id: item_info.data[0].id,
          part_number: item_info.data[0].part_number,
          sku: item_info.data[0].sku,
          name: item_info.data[0].name,
          model: item_info.data[0].model,
          description: item_info.data[0].description,
          identifier: item_info.data[0].identifier,
          category: item_info.data[0].category_id,
          brand: item_info.data[0].brand_id,
          manufacturer: item_info.data[0].manufacturer_id,
          subcategory: item_info.data[0].subcategory_id || null,
          storage: item_info.data[0].storage_id || null,
          cpu: item_info.data[0].cpu_id,
          ram: item_info.data[0].ram_id || null,
          operating_system: item_info.data[0].operating_system_id || null,
          keyboard_type: item_info.data[0].keyboard_type_id || null,
          screen_size: item_info.data[0].screen_size_id || null,
          screen_type: item_info.data[0].screen_type_id || null,
          screen_resolution: item_info.data[0].screen_resolution_id || null,
          screen_technology: item_info.data[0].screen_technology_id || null,
          is_active: item_info.data[0].is_active || null,
          battery_type: item_info.data[0]?.battery_type?.split(',') || null,
        };
        form.setFieldsValue(item);
        setItemInfo(item_info.data[0]);
        setUpdateFlag(true);
        setIsActive(!(item_info.data[0].is_active === null || item_info.data[0].is_active === false));

        if ([1, 2, 3, 5].includes(item_info.data[0].category_id)) {
          const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=1, 2, 3, 5`);
          setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
        }

        if ([6].includes(item_info.data[0].category_id)) {
          const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=6`);
          setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
        }

        if ([7].includes(item_info.data[0].category_id) && [16].includes(item_info.data[0].subcategory_id)) {
          const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=7&subcategoryIds=16`);
          setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
        }
      }

      setQuery(searchQuery === null ? undefined : searchQuery);

      setCategories(categoriesList.data.map((list) => ({ value: list.id, label: list.name })));
      setBrands(brandsList.data.map((list) => ({ value: list.id, label: list.name })));
      setManufacturers(manufacturersList.data.map((list) => ({ value: list.id, label: list.name })));
      setStorages(storagesList.data.map((list) => ({ value: list.id, label: list.name })));
      setCpus(cpusList.data.map((list) => ({ value: list.id, label: list.name })));
      setRams(ramsList.data.map((list) => ({ value: list.id, label: list.name })));
      setOperatingSystems(operatingSystemsList.data.map((list) => ({ value: list.id, label: list.name })));
      setKeyboards(keyboardsList.data.map((list) => ({ value: list.id, label: list.name })));
      setScreenSizes(screenSizesList.data.map((list) => ({ value: list.id, label: list.name })));
      setScreenTypes(screenTypesList.data.map((list) => ({ value: list.id, label: list.name })));
      setScreenResolutions(screenResolutionsList.data.map((list) => ({ value: list.id, label: list.name })));
      setScreenTechnologies(screenTechnologiesList.data.map((list) => ({ value: list.id, label: list.name })));
      setSubcategories(subcategoriesList.data.map((list) => ({ value: list.id, label: list.name })));
    }

    loadDropdownboxes();
  }, []); //eslint-disable-line

  const onFinish = async (values) => {
    const itemExists = await api.get(`items?part_number=${encodeURIComponent(values.part_number)}`);

    if (updateFlag === false && itemExists.data.length > 0) {
      toast.error('Item Part Number is already registered');
      return;
    }
    // const log_id = uuidv4();

    const newItem = {
      part_number: values.part_number,
      sku: values.sku,
      name: values.name,
      model: values.model,
      description: values.description,
      category_id: values.category,
      identifier: values.identifier,
      brand_id: values.brand,
      manufacturer_id: values.manufacturer,
      subcategory_id: values.subcategory || null,
      storage_id: values.storage || null,
      cpu_id: values.cpu,
      ram_id: values.ram || null,
      operating_system_id: values.operating_system || null,
      keyboard_type_id: values.keyboard_type || null,
      screen_size_id: values.screen_size || null,
      screen_type_id: values.screen_type || null,
      screen_resolution_id: values.screen_resolution || null,
      screen_technology_id: values.screen_technology || null,
      is_active: isActive,
      battery_type: values.battery_type?.join(',') || null,
    };

    try {
      if (updateFlag) {
        newItem.id = itemInfo.id;
        await api.post('logs', {
          // log_id,
          routine: `${itemInfo.part_number} Item Updated`,
          action: 'Update',
          previous_data: JSON.stringify(itemInfo),
          new_data: JSON.stringify(newItem),
          user_id: profile.id,
          reference_id: newItem.id,
        });
        const itemUpdated = await api.put('items', newItem);
        toast.success('Item Updated.');
        history.push(`/items?searchQuery=${itemUpdated.data.part_number}`);
      } else {
        const newItemCreated = await api.post('items', newItem);
        await api.post('logs', {
          // log_id,
          routine: `${newItemCreated.data.part_number} Item Created`,
          action: 'Create',
          new_data: JSON.stringify(newItemCreated.data),
          user_id: profile.id,
          reference_id: newItemCreated.data.id,
        });
        toast.success('New Item created.');
        history.push(`/items?searchQuery=${newItemCreated.data.part_number}`);
      }
    } catch (error) {
      toast.error('Error loading Item. Contact RBMS administrator.');
    }
  };

  async function handleOnChangeChecks() {
    const category_id = form.getFieldValue('category');
    const subcategory_id = form.getFieldValue('subcategory');

    form.setFieldsValue({ keyboard_type: null, storage: null, ram: null, screen_type: null, cpu: null });

    setIdentifierList([]);

    if ([1, 2, 3, 5].includes(category_id)) {
      // 2-in-1, laptops, tablets, desktop
      const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=1, 2, 3, 5`);
      setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
    }

    if ([4, 7, 9, 11, 13, 14, 15].includes(category_id)) {
      // accessories
      form.setFieldsValue({ keyboard_type: 7, storage: 256, ram: 12, screen_type: 4, cpu: 93 });
    }

    if ([10].includes(category_id)) {
      // accessories
      form.setFieldsValue({ keyboard_type: 7, storage: 256, ram: 12, cpu: 93 });
    }

    if ([6, 12].includes(category_id)) {
      // hard drive
      const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=6`);
      setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
      form.setFieldsValue({ keyboard_type: 7, ram: 12, screen_type: 4, cpu: 93 });
    }

    if ([7].includes(category_id) && [16].includes(subcategory_id)) {
      form.setFieldsValue({ keyboard_type: null, storage: null, ram: null, screen_type: null, cpu: null });
      // cards and mememories
      const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=7&subcategoryIds=16`);
      setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
      form.setFieldsValue({ keyboard_type: 7, storage: 256, screen_type: 4, cpu: 93 });
    }

    if (![1, 2, 3].includes(category_id)) form.setFieldsValue({ battery_type: null });

    setBatteryRequired([1, 2, 3].includes(category_id));
  }

  async function handleOnChangeSubcategory() {
    const category_id = form.getFieldValue('category');
    const subcategory_id = form.getFieldValue('subcategory');

    form.setFieldsValue({ keyboard_type: null, storage: null, ram: null, screen_type: null, cpu: null });

    setIdentifierList([]);

    if ([7].includes(category_id) && [16].includes(subcategory_id)) {
      const { data: identifierData } = await api.get(`items/list/identifierBycategory?categoryIds=7&subcategoryIds=16`);
      setIdentifierList(identifierData.map((list) => ({ value: list.identifier, label: list.identifier })));
      form.setFieldsValue({ keyboard_type: 7, storage: 256, screen_type: 4, cpu: 93 });
    }
  }

  return (
    <Container>
      <Card>
        <Form {...layout} form={form} name="nest-messages" onFinish={onFinish}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={() => (query === undefined ? `/items` : `/items?searchQuery=${query}`)}>
              <Button variant="contained" type="primary" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            {updateFlag === true ? <CardHeader subheader={`${itemInfo.part_number}`} title="Update Item" /> : <CardHeader title="New Item" />}
            <div />
          </div>

          <Card title="Item Information" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="sku" label="SKU" rules={[{ required: true, message: 'SKU is required' }]}>
                  <Input allowClear placeholder="SKU" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="part_number" label="Mfr Part Number" rules={[{ required: true, message: 'Mfr Part Number is required' }]}>
                  <Input allowClear placeholder="Mfr Part Number" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
                  <Input allowClear placeholder="Item Name" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="model" label="Model" rules={[{ required: true, message: 'Model is required' }]}>
                  <Input allowClear placeholder="Item Model" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="is_active" wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                  <Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)}>
                    Is Active
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Description is required' }]}>
                  <TextArea rows={3} allowClear placeholder="Item Description" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Item Selection" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Category is required' }]}>
                  <Select
                    id="category_id"
                    placeholder="Category"
                    options={categories}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    onChange={handleOnChangeChecks}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="brand" label="Brand" rules={[{ required: true, message: 'Brand is required' }]}>
                  <Select id="brand_id" placeholder="Brand" options={brands} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="manufacturer" label="Manufacturer" rules={[{ required: true, message: 'Manufacturer is required' }]}>
                  <Select id="manufacturer_id" placeholder="Manufacturer" options={manufacturers} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="subcategory" label="Subcategory" rules={[{ required: false }]}>
                  <Select
                    id="subcategory_id"
                    placeholder="Subcategory"
                    options={subcategories}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    onChange={handleOnChangeSubcategory}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title="Item Specification" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="storage" label="Storage" rules={[{ required: true, message: 'Storage is required' }]}>
                  <Select id="storage_id" placeholder="Storage" options={storages} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="cpu" label="CPU" rules={[{ required: true, message: 'CPU is required' }]}>
                  <Select id="cpu_id" placeholder="CPU" options={cpus} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="ram" label="RAM" rules={[{ required: true, message: 'RAM is required' }]}>
                  <Select id="ram_id" placeholder="RAM" options={rams} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="operating_system" label="OS" rules={[{ required: false }]}>
                  <Select id="operating_system_id" placeholder="Operating System" options={operatingSystems} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="keyboard_type" label="Keyboard" rules={[{ required: true, message: 'Keyboard is required' }]}>
                  <Select id="keyboard_id" placeholder="Keyboard" options={keyboards} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              {identifierList.length > 0 && (
                <Col span={12}>
                  <Form.Item name="identifier" label="Identifier" rules={[{ required: true, message: 'Identifier is required' }]}>
                    <Select id="identifier" placeholder="Identifier" options={identifierList} showSearch allowClear optionFilterProp="label" />
                  </Form.Item>
                </Col>
              )}

              {batteryRequired && (
                <Col span={12}>
                  <Form.Item name="battery_type" label="Battery Type" rules={[{ required: batteryRequired }]}>
                    <CheckboxGroup options={checkboxOptions} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Card>

          <Card title="Item Specifics: Display" headStyle={{ backgroundColor: 'whitesmoke' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="screen_size" label="Screen Size" rules={[{ required: false }]}>
                  <Select id="screen_size_id" placeholder="Screen Size" options={screenSizes} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="screen_type" label="Screen Type" rules={[{ required: true, message: 'Screen Type is required' }]}>
                  <Select id="screen_type_id" placeholder="Screen Type" options={screenTypes} showSearch allowClear optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="screen_resolution" label="Resolution" rules={[{ required: false }]}>
                  <Select
                    id="screen_resolution_id"
                    placeholder="Screen Resolution"
                    options={screenResolutions}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="screen_technology" label="Technology" rules={[{ required: false }]}>
                  <Select
                    id="screen_technology_id"
                    placeholder="Screen Technology"
                    options={screenTechnologies}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Divider />

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
}
