import React from 'react';
import { Switch } from 'react-router-dom';

import Admin from '~/pages/Admin';
import Assessment from '~/pages/Assessment';
import LotAssessment from '~/pages/Assessment/LotAssessment';
import AssessmentProcessing from '~/pages/Assessment/UnitAssessment';
import Branch from '~/pages/Branch';
import BranchEdit from '~/pages/Branch/Edit';
import BranchNew from '~/pages/Branch/New';
import Customer from '~/pages/Customer';
import CustomerEdit from '~/pages/Customer/Edit';
import CustomerNew from '~/pages/Customer/New';
import Dashboard from '~/pages/Dashboard';
import Department from '~/pages/Department';
import DepartmentNew from '~/pages/Department/New';
import Downstream from '~/pages/Downstream';
import DownstreamEdit from '~/pages/Downstream/Edit';
import InspectionOptions from '~/pages/InspectionOptions';
import Instructions from '~/pages/Instructions';
import InstructionsEdit from '~/pages/Instructions/Edit';
import Inventory from '~/pages/Inventory';
import InventoryAssetsListLookup from '~/pages/Inventory/AssetsListLookup';
import InventoryAudit from '~/pages/Inventory/Audit';
import InventoryAuditItems from '~/pages/Inventory/Audit/Items';
import InventoryBulk from '~/pages/Inventory/Bulk';
import InventoryBulkImport from '~/pages/Inventory/BulkImport';
import InventoryEdit from '~/pages/Inventory/Edit';
import InventoryCostReport from '~/pages/Inventory/Edit/CostReport';
import InventoryArraySN from '~/pages/Inventory/inventoryArrayBySN';
import InventoryDetails from '~/pages/Inventory/InventoryDetails';
import InventoryInventoryTransfer from '~/pages/Inventory/InventoryTransfer';
import InventoryNew from '~/pages/Inventory/New';
import PhysicalInventory from '~/pages/Inventory/PhysicalCount';
import InventoryLocationItems from '~/pages/Inventory/PhysicalCount/InventoryLocationItems';
import InventoryLocations from '~/pages/Inventory/PhysicalCount/InventoryLocations';
import PhysicalInventoryLocationsDashboard from '~/pages/Inventory/PhysicalCount/InventoryLocationsDashboard';
import NewPhysicalInventory from '~/pages/Inventory/PhysicalCount/NewInventory';
import InventoryProcessing from '~/pages/Inventory/Processing';
import InventoryStockAnalyzer from '~/pages/Inventory/StockAnalyzer';
import FeedDatabase from '~/pages/Inventory/StockAnalyzer/FeedDatabase';
import InventoryBatteries from '~/pages/InventoryBatteries';
import BatteryListByPN from '~/pages/InventoryBatteries/BatteryLisByPN';
import InventoryParts from '~/pages/InventoryParts';
import InventoryPartsBulkImport from '~/pages/InventoryParts/BulkImport';
import InventoryPartsEdit from '~/pages/InventoryParts/Edit';
import InventoryPartsLog from '~/pages/InventoryParts/Log';
import InventoryPartsNew from '~/pages/InventoryParts/New';
import InventorySearch from '~/pages/InventorySearch';
import Item from '~/pages/Item';
import ItemEdit from '~/pages/ItemEdit';
import ItemNew from '~/pages/ItemNew';
import Listings from '~/pages/Listings';
import ListingsEdit from '~/pages/Listings/Edit';
import ListingsNew from '~/pages/Listings/New';
import Location from '~/pages/Location';
import ChangeLocation from '~/pages/Location/ChangeLocation';
import LocationList from '~/pages/Location/LocationList';
import LocationLogs from '~/pages/Location/LocationLogs';
import Orders from '~/pages/Orders';
import OrderDetail from '~/pages/Orders/Detail';
import OrdersEdit from '~/pages/Orders/Edit';
import InternalOrders from '~/pages/Orders/InternalOrder';
import InternalOrderEdit from '~/pages/Orders/InternalOrder/Edit';
import NewInternalOrder from '~/pages/Orders/InternalOrder/New';
import OrdersNew from '~/pages/Orders/New';
import OrderShipment from '~/pages/Orders/Shipment';
import Profile from '~/pages/Profile';
import PurchaseOrders from '~/pages/PurchaseOrders';
import PurchaseOrderDetail from '~/pages/PurchaseOrders/Detail';
import Receiving from '~/pages/Receiving';
import InventoryOfReceiving from '~/pages/Receiving/InventoryOfReceiving';
import InventoryPartsOfReceiving from '~/pages/Receiving/InventoryPartsOfReceiving';
import Reports from '~/pages/Reports';
import DownloadOrders from '~/pages/Reports/DownloadOrders';
import ReportHardDrive from '~/pages/Reports/HardDrive';
import Returns from '~/pages/Returns';
import SignIn from '~/pages/SignIn';
import Storage from '~/pages/Storage';
import StorageNewEdit from '~/pages/Storage/New_Edit';
import Store from '~/pages/Store';
import StoreEdit from '~/pages/Store/Edit';
import StoreNew from '~/pages/Store/New';
import Super from '~/pages/Super';
import Supplier from '~/pages/Supplier';
import SupplierEdit from '~/pages/Supplier/Edit';
import SupplierNew from '~/pages/Supplier/New';
import TechArea from '~/pages/TechArea';
import Technician from '~/pages/Technician';
import TechnicianEdit from '~/pages/Technician/Edit';
import TechnicianNew from '~/pages/Technician/New';

import { RouteWrapper } from './Route';
import SkidChangeLocation from '~/pages/Location/SkidChangeLocation';
import InventoryComplete from '~/pages/InventoryComplete';

// import InventoryReport from '~/pages/Inventory/InventoryReport';
// import InventoryPartsBulk from '~/pages/InventoryParts/Bulk';
// import ServiceRepair from '~/pages/ServiceRepair';
// import ServiceDetails from '~/pages/ServiceRepair/ServiceDetails';
// import ServiceNew from '~/pages/ServiceRepair/New';
// import ServiceInventoryReport from '~/pages/ServiceRepair/ServiceInventoryReport';

export default function Routes() {
  return (
    <Switch>
      <RouteWrapper path="/" exact component={SignIn} />
      <RouteWrapper path="/dashboard" component={Dashboard} isPrivate />

      <RouteWrapper path="/profile" component={Profile} isPrivate />

      <RouteWrapper path="/downstream" component={Downstream} isPrivate />
      <RouteWrapper path="/downstreamEdit/:id" component={DownstreamEdit} isPrivate />

      <RouteWrapper path="/items" component={Item} isPrivate />
      <RouteWrapper path="/itemNew" component={ItemNew} isPrivate />
      <RouteWrapper path="/itemEdit/:id" component={ItemEdit} isPrivate />

      <RouteWrapper path="/inventory" component={Inventory} isPrivate />
      <RouteWrapper path="/inventoryComplete" component={InventoryComplete} isPrivate />
      <RouteWrapper path="/inventoryNew" component={InventoryNew} isPrivate />
      <RouteWrapper path="/inventoryEdit/:id" component={InventoryEdit} isPrivate />
      <RouteWrapper path="/inventoryCostReport" component={InventoryCostReport} isPrivate />
      <RouteWrapper path="/inventoryBulk" component={InventoryBulk} isPrivate />
      <RouteWrapper path="/inventoryProcessing/" exact component={InventoryProcessing} isPrivate />
      <RouteWrapper path="/inventoryProcessing/:id" exact component={InventoryProcessing} isPrivate />
      <RouteWrapper path="/inventoryBulkImport" component={InventoryBulkImport} isPrivate />
      <RouteWrapper path="/inventoryStockAnalyzer" component={InventoryStockAnalyzer} isPrivate />
      <RouteWrapper path="/inventoryInventoryTransfer" component={InventoryInventoryTransfer} isPrivate />
      <RouteWrapper path="/inventoryFeedDatabase" component={FeedDatabase} isPrivate />
      <RouteWrapper path="/inventoryAssetsListLookup" component={InventoryAssetsListLookup} isPrivate />
      {/* <RouteWrapper path="/inventoryReport" component={InventoryReport} isPrivate /> */}
      <RouteWrapper path="/inventoryDetails" component={InventoryDetails} isPrivate />

      <RouteWrapper path="/inventoryAudit" component={InventoryAudit} isPrivate />
      <RouteWrapper path="/inventoryAuditItems/:id" component={InventoryAuditItems} isPrivate />

      {/* <RouteWrapper path="/download/inventory" component={DownloadInventory} isPrivate /> */}

      <RouteWrapper path="/inventoryParts" component={InventoryParts} isPrivate />
      <RouteWrapper path="/inventoryPartsNew" component={InventoryPartsNew} isPrivate />
      <RouteWrapper path="/inventoryPartsEdit" component={InventoryPartsEdit} isPrivate />
      {/* <RouteWrapper path="/inventoryPartsBulk" component={InventoryPartsBulk} isPrivate /> */}
      <RouteWrapper path="/inventoryPartsBulkImport" component={InventoryPartsBulkImport} isPrivate />
      <RouteWrapper path="/inventoryPartsLog" component={InventoryPartsLog} isPrivate />

      <RouteWrapper path="/branches" component={Branch} isPrivate />
      <RouteWrapper path="/branchesNew" component={BranchNew} isPrivate />
      <RouteWrapper path="/branchesEdit/:zip_code" component={BranchEdit} isPrivate />

      <RouteWrapper path="/listings" component={Listings} isPrivate />
      <RouteWrapper path="/listingsNew" component={ListingsNew} isPrivate />
      <RouteWrapper path="/listingsEdit/:sku" component={ListingsEdit} isPrivate />

      <RouteWrapper path="/orders" component={Orders} isPrivate />
      <RouteWrapper path="/order/:id" component={OrderDetail} isPrivate />
      <RouteWrapper path="/ordersNew" component={OrdersNew} isPrivate />
      <RouteWrapper path="/ordersEdit" component={OrdersEdit} isPrivate />
      <RouteWrapper path="/orderShipment/:order_id" component={OrderShipment} isPrivate />

      <RouteWrapper path="/internalOrders" component={InternalOrders} isPrivate />
      <RouteWrapper path="/internalOrderNew" component={NewInternalOrder} isPrivate />
      <RouteWrapper path="/InternalOrderEdit" component={InternalOrderEdit} isPrivate />

      <RouteWrapper path="/purchaseOrders" exact component={PurchaseOrders} isPrivate />
      <RouteWrapper path="/purchaseOrders/:po_number" exact component={PurchaseOrderDetail} isPrivate />

      <RouteWrapper path="/receiving" component={Receiving} isPrivate />
      <RouteWrapper path="/inventoryOfReceiving" component={InventoryOfReceiving} isPrivate />
      <RouteWrapper path="/inventoryPartsOfReceiving" component={InventoryPartsOfReceiving} isPrivate />

      <RouteWrapper path="/stores" component={Store} isPrivate />
      <RouteWrapper path="/storesNew" component={StoreNew} isPrivate />
      <RouteWrapper path="/storesEdit" component={StoreEdit} isPrivate />

      <RouteWrapper path="/customers" component={Customer} isPrivate />
      <RouteWrapper path="/customersNew" component={CustomerNew} isPrivate />
      <RouteWrapper path="/customersEdit" component={CustomerEdit} isPrivate />

      <RouteWrapper path="/suppliers" component={Supplier} isPrivate />
      <RouteWrapper path="/suppliersNew" component={SupplierNew} isPrivate />
      <RouteWrapper path="/suppliersEdit" component={SupplierEdit} isPrivate />

      <RouteWrapper path="/technicians" component={Technician} isPrivate />
      <RouteWrapper path="/techniciansNew" component={TechnicianNew} isPrivate />
      <RouteWrapper path="/techniciansEdit/:name" component={TechnicianEdit} isPrivate />

      <RouteWrapper path="/reports" component={Reports} isPrivate />
      <RouteWrapper path="/download/orders" component={DownloadOrders} isPrivate />

      <RouteWrapper path="/reportHardDrive/:storageSerialNumber" component={ReportHardDrive} isPrivate />

      <RouteWrapper path="/returns" component={Returns} isPrivate />
      <RouteWrapper path="/tech_area" component={TechArea} isPrivate />
      <RouteWrapper path="/admin" component={Admin} isPrivate />
      <RouteWrapper path="/super" component={Super} isPrivate />

      <RouteWrapper path="/instructions" component={Instructions} isPrivate />
      <RouteWrapper path="/instructionsEdit/:field" component={InstructionsEdit} isPrivate />

      <RouteWrapper path="/storage" component={Storage} isPrivate />
      <RouteWrapper path="/storageNew" component={StorageNewEdit} isPrivate />

      <RouteWrapper path="/assessment" component={Assessment} isPrivate />
      <RouteWrapper path="/lotAssessment" component={LotAssessment} isPrivate />
      <RouteWrapper path="/unitAssessment/:id" component={AssessmentProcessing} isPrivate />

      <RouteWrapper path="/departments" component={Department} isPrivate />
      <RouteWrapper path="/departmentsNew" component={DepartmentNew} isPrivate />

      <RouteWrapper path="/inspectionOptions" component={InspectionOptions} isPrivate />

      <RouteWrapper path="/location" component={Location} isPrivate />
      <RouteWrapper path="/locationList" component={LocationList} isPrivate />
      <RouteWrapper path="/changeLocation" component={ChangeLocation} isPrivate />
      <RouteWrapper path="/skid-change-location" component={SkidChangeLocation} isPrivate />
      <RouteWrapper path="/locationLogs" component={LocationLogs} isPrivate />

      <RouteWrapper path="/physicalInventory" component={PhysicalInventory} isPrivate />
      <RouteWrapper path="/physicalInventoryLocations/:physicalInventoryId" component={InventoryLocations} isPrivate />
      <RouteWrapper path="/physicalInventoryLocationItems/:physicalInventoryId/:physicalInventoryLocationId" component={InventoryLocationItems} isPrivate />
      <RouteWrapper path="/newPhysicalInventory" component={NewPhysicalInventory} isPrivate />
      <RouteWrapper path="/physicalInventoryLocationsDashboard/:physicalInventoryId" component={PhysicalInventoryLocationsDashboard} isPrivate />

      {/* <RouteWrapper path="/serviceRepair" component={ServiceRepair} isPrivate />
      <RouteWrapper path="/serviceDetails" component={ServiceDetails} isPrivate />
      <RouteWrapper path="/serviceNew" component={ServiceNew} isPrivate />
      <RouteWrapper path="/serviceInventoryReport" component={ServiceInventoryReport} isPrivate /> */}

      <RouteWrapper path="/inventoryReportArraySN" component={InventoryArraySN} isPrivate />

      <RouteWrapper path="/inventoryBatteries" component={InventoryBatteries} isPrivate />
      <RouteWrapper path="/inventoryBatteriesListByPN/:item_id" component={BatteryListByPN} isPrivate />

      <RouteWrapper path="/inventorySearch" component={InventorySearch} isPrivate />

      <RouteWrapper path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
