import { BarsOutlined, CalculatorOutlined, FileDoneOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { MuiThemeProvider, Switch } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Card, Empty, Space, Spin, Typography } from 'antd';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';
import { Datatable } from './Components/Datatable';
import InventorySummaryByLocation from './InventorySummaryByLocation';
import { Container } from './styles';

export default function Inventory({ location }) {
  const [searchTxt, setSearchTxt] = useState('');
  const [zeroQty, setZeroQty] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationFilters, setLocationFilters] = useState(null);
  const [checkedLocations, setCheckedLocations] = useState(null);

  const searchInputRef = useRef(searchTxt);

  const profile = useSelector((state) => state.user.profile);
  const { Paragraph } = Typography;

  const handleLocationFilterChange = useCallback(
    async (param) => {
      const params = new URLSearchParams();
      params.append('searchQuery', encodeURIComponent(searchTxt));
      params.append('zeroQty', zeroQty);
      params.append('locationFilters', param);

      const response = await api.get(`inventory?${params}`);

      if (!response.data.ok) {
        toast.warn('No items found!');
        return;
      }
      setInventory(response.data);
    },
    [searchTxt, zeroQty]
  );

  const handleSearch = useCallback(
    (searchQueryParam = '') => {
      const { search } = location;
      const paramsUrl = new URLSearchParams(search);
      const searchQuery = paramsUrl.get('searchQuery');
      const showZeroQuery = paramsUrl.get('zeroQty');

      setZeroQty(() => showZeroQuery === 'true');
      setSearchTxt(() => decodeURIComponent(searchQuery).trim());

      async function loadItems() {
        setLoading(true);

        const params = new URLSearchParams();
        params.append('searchQuery', encodeURIComponent(searchQueryParam.trim()));
        params.append('zeroQty', zeroQty);

        if (checkedLocations) {
          params.append('locationFilters', checkedLocations);
        }

        history.replace(`inventory?${params}`);

        const response = await api.get(`inventory?${params}`);

        if (response.data?.length === 0 && searchQueryParam !== null) {
          toast.warn('No items found!');
        }

        if (!locationFilters) {
          setLocationFilters(() => Object.keys(response.data.inventoryByLocation).filter((el) => el !== 'Total'));
        }
        setInventory(response.data);

        setLoading(false);
      }
      loadItems();
    },
    [checkedLocations, location, locationFilters, zeroQty]
  );

  useEffect(() => {
    async function loadItems() {
      try {
        const { search } = location;
        const paramsUrl = new URLSearchParams(search);
        const searchQuery = paramsUrl.get('searchQuery');
        const showZeroQuery = paramsUrl.get('zeroQty');

        if (!searchQuery) return;
        setZeroQty(() => showZeroQuery === 'true');
        setSearchTxt(() => decodeURIComponent(searchQuery).trim());

        setLoading(true);

        const params = new URLSearchParams();
        params.append('searchQuery', searchQuery.trim());
        params.append('zeroQty', showZeroQuery);

        if (checkedLocations) {
          params.append('locationFilters', checkedLocations);
        }

        const response = await api.get(`inventory?${params}`);
        if (response.data?.length === 0 && searchQuery !== null) {
          toast.warn('No items found!');
        }
        setInventory(response.data);

        setLoading(false);
        if (searchQuery) {
          setTimeout(() => {
            if (searchInputRef.current) {
              searchInputRef.current.value = decodeURIComponent(searchQuery).trim();
              searchInputRef.current.focus();
            }
          }, 0);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }

    loadItems();
  }, [checkedLocations, location]);

  function columnLink(value, tableMeta) {
    return (
      <Link
        to={() => {
          const id = tableMeta.tableData.find((el) => el[3] === value || el.part_number === value);
          if (searchTxt === null || searchTxt === undefined) {
            return `inventoryEdit/${id[0] || id.id}`;
          }
          return `inventoryEdit/${id[0] || id.id}?searchQuery=${searchTxt}&showZero=${zeroQty}`;
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Paragraph style={{ color: '#1890ff' }} copyable>
          {value}
        </Paragraph>
      </Link>
    );
  }

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'brand',
      label: 'Brand',
      options: {
        filter: true,
        // filterList: ['Kioxia'],
        sort: true,
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'part_number',
      label: 'Part Number',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => columnLink(value, tableMeta),
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <Paragraph style={{ maxWidth: '450px' }} copyable>
            {value}
          </Paragraph>
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'cpu',
      label: 'CPU',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'ram',
      label: 'RAM',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'screen_type',
      label: 'Screen Type',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'New',
      label: 'New',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'OB',
      label: 'OB',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'U',
      label: 'U',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'A',
      label: 'A',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },

    {
      name: 'B',
      label: 'B',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'C',
      label: 'C',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'D',
      label: 'D',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'FA',
      label: 'FA',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'TBI',
      label: 'TBI',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value === 0 ? '' : value),
      },
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Link
            to={() => {
              return `/inventoryDetails?partNumber=${tableMeta.rowData[3]}&searchQuery=${encodeURIComponent(searchTxt.trim())}&showZero=${zeroQty}`;
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InfoIcon />
          </Link>
        ),
      },
    },
    // {
    //   name: 'details',
    //   label: 'Details',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => (
    //       <Button
    //         type="text"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           history.push(`/inventoryDetails?partNumber=${tableMeta.rowData[3]}&searchQuery=${encodeURIComponent(searchTxt.trim())}&showZero=${zeroQty}`);
    //         }}
    //       >
    //         <InfoIcon />
    //       </Button>
    //     ),
    //   },
    // },
  ];

  const options = {
    fixedHeaderOptions: { xAxis: true, yAxis: true },
    filterType: 'multiselect',
    expandableRowsOnClick: false,
    pagination: true,
    download: true,
    print: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 150],
    search: false,
    responsive: 'scrollMaxHeight',
    checkboxSelection: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    tableBodyHeight: '799px',
    tableBodyMaxHeight: 'calc(100vh - 348px) !important',

    /**
     * Callback function that triggers when filters have changed. function(changedColumn: string, filterList: array, type: enum('checkbox', 'dropdown', 'multiselect', 'textField', 'custom', 'chip', 'reset'), changedColumnIndex, displayData) => void
     */
    // onFilterChange: (changedColumn, filterList, type) => {
    //   console.log('changedColumn, filterList, type ', changedColumn, filterList, type);
    //   // if (type === 'reset') {
    //   //   setCheckedLocations(null);
    //   //   return;
    //   // }
    //   // const checked = filterList.map((el) => el[0]);
    //   // setCheckedLocations(checked);
    // },

    customToolbarSelect: () => {},
    customSort: (data, colIndex, order) =>
      data.sort((a, b) => {
        a = a.data[colIndex] || '';
        b = b.data[colIndex] || '';
        if (order === 'asc') return a.toString().localeCompare(b, undefined, { numeric: true });

        return b.toString().localeCompare(a, undefined, { numeric: true });
      }),
  };

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            margin: 0,
            paddingBottom: '2px',
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: 'calc(100vh - 348px) !important',
          },
        },
      },
    });

  const theme = getMuiTheme();

  if (loading) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <Container>
      <Card size="small">
        <Space wrap style={{ display: 'flex' }}>
          <Link to="/inventoryNew">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add
            </Button>
          </Link>

          <Link to="/inventoryProcessing">
            <Button type="primary" icon={<FileDoneOutlined />} size="large">
              Processing
            </Button>
          </Link>

          <Link to="/inventoryAssetsListLookup">
            <Button type="primary" icon={<FileSearchOutlined />} size="large">
              Assets List Lookup
            </Button>
          </Link>

          {(profile.type === 999 || profile.type === 3 || profile.type === 5) && (
            <Link to="/inspectionOptions">
              <Button type="primary" icon={<BarsOutlined />} size="large">
                Inspection Options
              </Button>
            </Link>
          )}

          <Link to="/physicalInventory">
            <Button type="primary" icon={<CalculatorOutlined />} size="large">
              Physical Inventory
            </Button>
          </Link>
        </Space>
      </Card>

      <Space>
        <InputText
          ref={searchInputRef}
          placeholder="Search"
          autoComplete="off"
          autoFocus
          onBlur={(e) => {
            e.target.value = e.target.value.toUpperCase().trim();
          }}
          IconAfterProp={<MagnifyingGlass onClick={() => handleSearch(searchInputRef.current.value)} style={{ cursor: 'pointer' }} size={20} />}
          onPressEnter={handleSearch}
        />
        <span>
          <Switch checked={zeroQty} onChange={(e) => setZeroQty(e.target.checked)} name="zeroQty" color="primary" />
          Show items with zero qty
        </span>
        {inventory.inventoryByLocation && Object.keys(inventory.inventoryByLocation).length > 0 && (
          <InventorySummaryByLocation
            data={inventory.inventoryByLocation}
            setLocationFilters={setLocationFilters}
            setCheckedLocations={setCheckedLocations}
            checkedLocations={checkedLocations}
            handleSearch={handleSearch}
            handleLocationFilterChange={handleLocationFilterChange}
          />
        )}
      </Space>

      {inventory.inventoryCount && inventory.inventoryCount.length > 0 ? (
        <MuiThemeProvider theme={theme}>
          <Datatable title="Inventory" data={inventory.inventoryCount} columns={columns} options={options} />
        </MuiThemeProvider>
      ) : (
        <Empty
          description={<span>Use the search box above to list inventory</span>}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh',
          }}
        />
      )}
    </Container>
  );
}
