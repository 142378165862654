import { CheckCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { Button as MUIButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Alert, Divider, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Spin, Tooltip, Typography } from 'antd';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { DebounceSelect } from '~/components/DebounceSelect';
import Table from '~/components/Table';
import { fetchItems } from '~/pages/Inventory/Processing/utils/fetchItems';
import api from '~/services/api';
import history from '~/services/history';

import { Container, OrderAlert, Table as StyledTable } from './styles';

const { Title, Text } = Typography;
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function OrdersEdit({ location }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const classes = useStyles();
  const log_id = uuidv4();
  const profile = useSelector((state) => state.user.profile);
  const { confirm } = Modal;

  const [loading, setLoading] = useState(true);
  const [notesStatus, setNotesStatus] = useState(false);
  const [orderChecklistItems, setOrderChecklistItems] = useState([]);
  const [orderInfoData, setOrderInfoData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsOptions, setOrderItemsOptions] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [partNumberStatus, setPartNumberStatus] = useState(false);
  const [partsQuantity, setPartsQuantity] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [serialNumberStatus, setSerialNumberStatus] = useState(false);
  const [showOverQuantityItemAlert, setShowOverQuantityItemAlert] = useState(false);
  const [disableAddButtonWhenInProgress, setDisableAddButtonWhenInProgress] = useState(false);
  const [orderItemSelected, setOrderItemSelected] = useState(null);

  const serialNumberRef = useRef('');
  const inventoryItemRef = useRef('');
  const notesRef = useRef('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const searchQueryParam = params.get('searchQuery');
    setSearchQuery(searchQueryParam);
    const order_id = params.get('order_id');

    async function loadDropdownboxes() {
      const orderInfo = await api.get(`orders/${order_id}`);
      const orderChecklistItemsList = await api.get(`ordersChecklist/${orderInfo.data.id}`);
      // const itemList = await api.get('items');

      setOrderNumber(orderInfo.data.order_num);
      setOrderStatus(orderInfo.data.status);
      setOrderInfoData(orderInfo.data);

      // setItemsPartNumberList(
      //   itemList.data
      //     .filter((el) => (el.category?.id === 9 || el.category?.id === 4) && el.is_active === true)
      //     .map((list) => ({ value: list.id, label: `${list.part_number} - ${list.name} - ${list.description}` }))
      // );

      setOrderItems(orderInfo.data.order_items);
      setOrderItemsOptions(orderInfo.data.order_items.map((item) => ({ label: item.title, value: item.id })));

      const newData = orderChecklistItemsList.data.map(async (item) => {
        const storage = [item.inventory_item?.storage, item.inventory_item?.storage_2].filter((element) => element).join(' / ');
        const ram = [item.inventory_item?.ram, item.inventory_item?.ram_2].filter((element) => element).join(' / ');
        const battery = [
          item.inventory_item?.battery_item?.part_number,
          item.inventory_item?.battery_item2?.part_number,
          item.inventory_item?.battery_item3?.part_number,
        ]
          .filter((element) => element)
          .join(' / ');

        const returnObject = {
          index: item.id,
          notes: item.notes,
          partNumber: item.item?.part_number,
          item: item.item?.name,
          itemId: item.item?.id,
          serialNumber: item.serial_number,
          inventoryItemId: item.inventory_items_id,
          technician: item.technician?.name,
          isProcessed: item.isProcessed,
          supervisorApproved: item.is_qc_approved,
          workInstructionId: item.work_instruction_id,
          wwan: item.inventory_item?.wwan_model,
          storage,
          ram,
          battery,
          qcTechnician: item.qc_technician?.name || null,
          condition: item.condition,
          orderItemId: item.order_items_id,
        };

        if (item.work_instruction_id !== null) {
          returnObject.workInstructionUrl = item.work_instruction?.url || null;
          returnObject.workInstructionName = item.work_instruction?.name || null;
        }
        return returnObject;
      });

      const results = await Promise.all(newData);
      setOrderChecklistItems(results);
      setLoading(false);
    }
    loadDropdownboxes();
  }, []); //eslint-disable-line

  const handleQCApproval = useCallback(
    async (status, index) => {
      if (profile?.type === 3 || profile?.type === 999) {
        const orderCheckListToUpdate = {
          order_id: orderInfoData.id,
          id: index,
          is_qc_approved: !status,
          qc_technician_id: profile.id,
        };

        await api.put('ordersChecklist', orderCheckListToUpdate);

        const newOrderItems = orderChecklistItems.map((el) => {
          if (el.index === index) {
            el.supervisorApproved = !status;
            el.qcTechnician = profile.name;
          }
          return el;
        });
        setOrderChecklistItems(newOrderItems);
      }
    },
    [orderChecklistItems, orderInfoData.id, profile.id, profile.name, profile.type]
  );

  const handleRemoveChecklistItem = async (rowInfo) => {
    try {
      const { index, serialNumber, itemId, orderItemId } = rowInfo;

      const newOrderItems = orderItems.map((item) => {
        if (item.id === orderItemId) {
          item.quantity_added_to_order -= 1;
        }
        return item;
      });

      setOrderItems([...newOrderItems]);

      if (serialNumber === undefined || serialNumber === null) {
        const getItemInfo = await api.get(`items/${itemId}`);

        await api.put(`inventory/${getItemInfo.data.inventory.id}`, {
          branch_id: getItemInfo.data.inventory.branch_id,
          item_id: getItemInfo.data.inventory.item_id,
          quantity: +getItemInfo.data.inventory.quantity + 1,
        });

        await api.delete(`ordersChecklist/${index}`);

        await api.post('inventoryLog', {
          log_id,
          item_id: itemId,
          routine: 'OrderChecklist',
          error: 'Item Found',
          action: 'Update',
          previous_data: getItemInfo.data.inventory.quantity,
          new_data: +getItemInfo.data.inventory.quantity + 1,
          user_id: profile.id,
        });

        setOrderChecklistItems(orderChecklistItems.filter((el) => el.index !== index));
        toast.success('Item removed successfully and is back in stock');

        if (orderItems.find((el) => el.quantity_added_to_order >= Number(el.quantity))) {
          setShowOverQuantityItemAlert(true);
        } else {
          setShowOverQuantityItemAlert(false);
          setDisableAddButtonWhenInProgress(false);
        }

        return;
      }

      const getAssetInfo = await api.post('assetDiagnostic/showBySerialNumber', { serialNumber });

      if (getAssetInfo.data.inventory_items.status !== 'AwaitingShipment' && getAssetInfo.data.inventory_items.status !== 'Failed' && profile?.type !== 999) {
        toast.success('This Serial Number cannot be removed because its status has changed');
        return;
      }

      await api.put('inventory_items', {
        items: [
          {
            serial_number: serialNumber,
            status: 'InStock',
            action: 'removedFromOrder',
          },
        ],
      });

      await api.delete(`ordersChecklist/${index}`);
      setOrderChecklistItems(orderChecklistItems.filter((el) => el.index !== index));

      if (orderItems.find((el) => el.quantity_added_to_order >= Number(el.quantity))) {
        setShowOverQuantityItemAlert(true);
      } else {
        setShowOverQuantityItemAlert(false);
        setDisableAddButtonWhenInProgress(false);
      }

      toast.success('Item removed successfully and is back in stock');
    } catch (error) {
      toast.error('Could not remove this item. Contact RBMS admin');
    }
  };

  const warning = useCallback(
    (data, id) => {
      confirm({
        title: 'Product not ready for shipping',
        width: 1000,
        okText: 'Inspect',
        cancelText: 'Cancel',
        onOk: () => {
          history.push(`/inventoryProcessing/${id}?orderId=${orderInfoData.id}&orderType=order&checklistOrderItem=${form.getFieldValue('checklistOrderItem')}`);
        },
        onCancel: () => {
          setDisableAddButtonWhenInProgress(false);
        },
        content: (
          <StyledTable>
            <thead>
              <tr key="header">
                <th>Inspection Item</th>
                <th>Status</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {data.map((el) => (
                <tr key={el.field}>
                  <td>{el.field}</td>
                  <td>Not passed</td>
                  <td>{el.message}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        ),
      });
    },
    [confirm, form, orderInfoData.id]
  );

  const onFinish = useCallback(
    async (values) => {
      const { checklistInventoryItem, checklistNotes, checklistOrderItem, checklistSerialNumber, checklistInventoryQuantity } = values;

      try {
        setDisableAddButtonWhenInProgress(true);
        const selectedOrderItem = orderItems.find((item) => item.id === checklistOrderItem);
        const IS_ORDER_ITEM_FILLED = selectedOrderItem.quantity <= selectedOrderItem.quantity_added_to_order;

        if (
          IS_ORDER_ITEM_FILLED ||
          (checklistInventoryQuantity && selectedOrderItem.quantity_added_to_order + checklistInventoryQuantity > selectedOrderItem.quantity)
        ) {
          toast.error('You’ve added the maximum quantity of items under this requested product. Consider adding more products to the order.');
          setDisableAddButtonWhenInProgress(false);
          return;
        }
        /**
         * Adding item using SERIAL number
         */

        if (checklistSerialNumber) {
          const serialNumberFormatted =
            checklistSerialNumber.substring(0, 4) === 'P/N:'
              ? checklistSerialNumber.substring(checklistSerialNumber.indexOf('S/N:') + 4).toUpperCase()
              : checklistSerialNumber.trim().toUpperCase();

          const { data: inventoryItemData } = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted });

          if (inventoryItemData.error) {
            throw new Error('Serial number not found');
          }

          if (inventoryItemData.status === 'Allocated') {
            throw new Error('Unit is allocated and cannot be added to this order');
          }

          const itemValidation = await api.get(`/inventory_items/item/validation/${inventoryItemData.id}`);

          if (!itemValidation.data.ok) {
            const hasInvalidStatus = itemValidation.data.validationResult.some((obj) => obj.field === 'Status');

            if (hasInvalidStatus) {
              Modal.error({
                title: 'Warning',
                content: itemValidation.data.validationResult.find((el) => el.field === 'Status').message,
              });
              serialNumberRef.current.focus();
              setDisableAddButtonWhenInProgress(false);
              return;
            }
            if (itemValidation.data.error) {
              Modal.error({
                title: 'Warning',
                content: itemValidation.data.error,
              });
              serialNumberRef.current.focus();
              setDisableAddButtonWhenInProgress(false);
              return;
            }
            warning(itemValidation.data.validationResult, inventoryItemData.id);
            return;
          }

          form.setFieldsValue({ checklistSerialNumber: serialNumberFormatted });

          const itemUserInfo = await api.get(`inventory_items/getUserAssignedToItem/${inventoryItemData.id}`);

          const isItemNotAssignedToUser = !itemUserInfo.data.ok;
          if (isItemNotAssignedToUser) {
            Modal.error({
              title: 'Warning',
              content: itemUserInfo.data.error,
            });
            setLoading(false);
            setDisableAddButtonWhenInProgress(false);
            return;
          }

          if (inventoryItemData.status === 'Downstream') {
            serialNumberRef.current.focus();
            throw new Error('This serial number status is Downstream and cannot be added to an order');
          }

          if (inventoryItemData.status === 'Sold') {
            serialNumberRef.current.focus();
            throw new Error('This serial number is Sold');
          }

          if (inventoryItemData.status === 'AwaitingShipment') {
            serialNumberRef.current.focus();
            throw new Error('This serial number is assigned to another order');
          }

          const newOrderChecklistItem = await api.post('ordersChecklist', {
            order_id: orderInfoData.id,
            serial_number: serialNumberFormatted,
            technician_id: profile.technician_id,
            order_items_id: checklistOrderItem,
            item_id: inventoryItemData.item.id,
            notes: checklistNotes,
          });

          // if (newOrderChecklistItem.data.error) {
          //   toast.error(newOrderChecklistItem.data.error);
          //   setDisableAddButtonWhenInProgress(false);
          //   return;
          // }

          await api.put('inventory_items', {
            items: [
              {
                serial_number: serialNumberFormatted,
                status: 'AwaitingShipment',
                action: 'addedToOrder',
                orderNumber,
              },
            ],
          });

          const storage = [inventoryItemData.storage, inventoryItemData.storage_2].filter((element) => element).join(' / ');
          const ram = [inventoryItemData.ram, inventoryItemData.ram_2].filter((element) => element).join(' / ');
          const battery = [
            inventoryItemData.battery_item?.part_number,
            inventoryItemData.battery_item2?.part_number,
            inventoryItemData.battery_item3?.part_number,
          ]
            .filter((element) => element)
            .join(' / ');

          const newChecklist = {
            index: newOrderChecklistItem.data.id,
            isProcessed: inventoryItemData.is_processed,
            serialNumber: serialNumberFormatted,
            technician: profile.name,
            partNumber: inventoryItemData.item.part_number,
            item: inventoryItemData.item.name,
            itemId: inventoryItemData.item.id,
            notes: checklistNotes,
            storage,
            ram,
            battery,
            condition: inventoryItemData.condition,
            orderItemId: checklistOrderItem,
          };

          const newOrderItems = orderItems.map((item) => {
            if (item.id === checklistOrderItem) {
              item.quantity_added_to_order += 1;
            }
            setDisableAddButtonWhenInProgress(false);
            return item;
          });

          setOrderItems([...newOrderItems]);

          setOrderChecklistItems([...orderChecklistItems, newChecklist]);
          form.setFieldsValue({ checklistSerialNumber: '' });
          serialNumberRef.current.focus();
          setDisableAddButtonWhenInProgress(false);
        }
      } catch (error) {
        if (error.message) {
          toast.error(error.message);
        }
        if (error.response?.data.message) {
          toast.error(error.response.data.message);
        }
        if (error.response?.data.error) {
          toast.error(error.response.data.error);
        }
      } finally {
        setDisableAddButtonWhenInProgress(false);
      }

      /**
       * Adding item using PART number
       */
      try {
        if (checklistSerialNumber === undefined || checklistSerialNumber === '') {
          if (checklistInventoryItem === undefined) {
            toast.error('You must include a serial number or an inventory item');
            setDisableAddButtonWhenInProgress(false);
            return;
          }

          const getItemInfo = await api.get(`items/${checklistInventoryItem}`);

          if (getItemInfo.data?.inventory === null) {
            toast.error('Item is not registered as an Inventory Item');
            setDisableAddButtonWhenInProgress(false);
            return;
          }

          if (getItemInfo.data.inventory.quantity <= 0) {
            toast.error('Item has quantity zero in stock');
            setDisableAddButtonWhenInProgress(false);
            return;
          }

          const newChecklistList = [];
          for (let i = 0; i < checklistInventoryQuantity; i++) {
            newChecklistList.push(i);
          }

          newChecklistList.forEach(async (_, index) => {
            const newOrderChecklistItem = await api.post('ordersChecklist/storeChecklistPartNumber', {
              order_id: orderInfoData.id,
              inventory_id: getItemInfo.data.inventory.id,
              technician_id: profile.technician_id,
              order_items_id: checklistOrderItem,
              item_id: checklistInventoryItem,
              notes: checklistNotes,
              work_instruction_id: getItemInfo.data.subcategory.work_instructions?.id,
            });

            const newChecklist = {
              index: newOrderChecklistItem.data.id,
              serialNumber: null,
              technician: profile.name,
              partNumber: getItemInfo.data.part_number,
              item: getItemInfo.data.name,
              itemId: checklistInventoryItem,
              notes: checklistNotes || '',
              workInstructionId: getItemInfo.data.subcategory.work_instructions?.id,
              workInstructionUrl: getItemInfo.data.subcategory.work_instructions?.url,
              workInstructionName: getItemInfo.data.subcategory.work_instructions?.name,
              orderItemId: checklistOrderItem,
            };
            newChecklistList[index] = newChecklist;
          });

          await api.post('inventoryLog', {
            log_id,
            item_id: checklistInventoryItem,
            routine: 'OrderChecklist',
            error: 'Added to order',
            action: 'AddToOrder',
            previous_data: getItemInfo.data.inventory.quantity,
            new_data: +getItemInfo.data.inventory.quantity - checklistInventoryQuantity,
            user_id: profile.id,
          });

          await api.put(`inventory/${getItemInfo.data.inventory.id}`, {
            branch_id: getItemInfo.data.inventory.branch_id,
            item_id: getItemInfo.data.inventory.item_id,
            quantity: +getItemInfo.data.inventory.quantity - checklistInventoryQuantity,
          });

          const newOrderItems = orderItems.map((item) => {
            if (item.id === checklistOrderItem) {
              item.quantity_added_to_order += checklistInventoryQuantity;
            }
            return item;
          });

          setOrderItems([...newOrderItems]);

          setOrderChecklistItems([...orderChecklistItems, ...newChecklistList]);

          setSerialNumberStatus(false);
          setPartsQuantity(0);

          form.setFieldsValue({ checklistWorkInstructions: null });
          form.setFieldsValue({ checklistInventoryItem: null });
          form.setFieldsValue({ checklistInventoryQuantity: '' });
          setDisableAddButtonWhenInProgress(false);
          inventoryItemRef.current.focus();
        }
      } catch (error) {
        toast.error('Error adding this item. Contact RBMS admin');
      }
      form.setFieldsValue({ checklistNotes: '' });
    },
    [form, log_id, orderChecklistItems, orderInfoData.id, orderItems, orderNumber, profile.id, profile.name, profile.technician_id, warning]
  );

  const columnsChecklist = [
    {
      name: '#',
      dataIndex: 'index',
      render: (_text, _row, index) => index + 1,
    },
    {
      name: 'Serial Number',
      dataIndex: 'serialNumber',
      render: (text) => text?.toUpperCase(),
    },
    {
      name: 'Part Number',
      dataIndex: 'partNumber',
    },
    {
      name: 'Item',
      dataIndex: 'item',
    },
    {
      name: 'Storage',
      dataIndex: 'storage',
    },
    {
      name: 'Ram',
      dataIndex: 'ram',
    },
    {
      name: 'Battery',
      dataIndex: 'battery',
    },
    {
      name: 'WWAN',
      dataIndex: 'wwan',
    },
    {
      name: 'Technician',
      dataIndex: 'technician',
    },
    {
      name: 'Actions',
      dataIndex: 'actions',
      render: (_text, record) => {
        if (orderStatus === 'awaiting_shipment' || orderStatus === 'on_hold' || orderStatus === 'cancelled' || profile?.type === 999) {
          return (
            <Popconfirm
              onConfirm={() => handleRemoveChecklistItem(record)}
              title="Are you sure you want to delete?"
              trigger="click"
              placement="left"
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className="button-error pure-button">
                Remove
              </button>
            </Popconfirm>
          );
        }
        return (
          <button disabled type="button" className="button-error pure-button">
            Remove
          </button>
        );
      },
    },
    {
      name: 'Inspection',
      dataIndex: 'inspection',
      render: (_text, row) =>
        row.serialNumber === null ? (
          // <Button type="link" href={row.workInstructionUrl} target="_blank">
          //   {row.workInstructionName}
          // </Button>
          <Link to={{ pathname: row.workInstructionUrl }} target="_blank" rel="noopener noreferrer">
            {row.workInstructionName}
          </Link>
        ) : (
          <Link
            to={`inventoryProcessing/${row.inventoryItemId}?orderId=${orderInfoData.id}&orderType=order&checklistOrderItem=${form.getFieldValue(
              'checklistOrderItem'
            )}`}
          >
            Test
          </Link>
        ),
    },
    {
      name: 'Inspected',
      dataIndex: 'isProcessed',
      render: (text, row) =>
        row.serialNumber === null ? (
          <ThumbUpIcon style={{ color: 'green' }} />
        ) : row.condition === 'New' ? (
          <ThumbUpIcon style={{ color: 'blue' }} />
        ) : text ? (
          <ThumbUpIcon style={{ color: 'green' }} />
        ) : (
          <ThumbDownIcon style={{ color: 'red' }} />
        ),
    },
    {
      name: 'Notes',
      dataIndex: 'notes',
    },
    {
      name: 'Supervisor Q.C.',
      dataIndex: 'supervisorApproved',
      render: (text, row) => (
        <CheckCircleTwoTone
          twoToneColor={text ? '#52c41a' : '#eb2f96'}
          style={{ fontSize: '20px', cursor: 'pointer' }}
          onClick={() => handleQCApproval(text, row.index)}
        />
      ),
    },
    {
      name: 'Supervisor',
      dataIndex: 'qcTechnician',
      render: (text, row) => (row.supervisorApproved ? text : ''),
    },
  ];

  const columnsItems = [
    {
      name: 'Item',
      dataIndex: 'title',
    },
    {
      name: 'Qty Ordered',
      dataIndex: 'quantity',
    },
    {
      name: 'Qty Added',
      dataIndex: 'quantity_added_to_order',
      render: (text) => (
        <Text type="warning" style={{ fontWeight: 'bolder' }}>
          {text}
        </Text>
      ),
    },
    {
      name: 'Manual Item',
      dataIndex: 'is_rbms_item',
      render: (text) => (text ? <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '20px' }} /> : ''),
    },
  ];

  const inventoryItemSelection = async (item) => {
    setSerialNumberStatus(true);
    setNotesStatus(false);

    form.setFieldsValue({ checklistWorkInstructions: null });

    try {
      const itemData = await api.get(`items/${item}`);
      const quantity = itemData.data.inventory.quantity || 0;
      setPartsQuantity(quantity);
      const subcategory = itemData.data?.subcategory;
      if (!subcategory) {
        throw new Error('No Subcategory for this item');
      }

      if (!itemData.data.subcategory.work_instructions) {
        setNotesStatus(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const clearItem = () => {
    setSerialNumberStatus(false);
    setNotesStatus(false);
    form.setFieldsValue({ checklistWorkInstructions: null });
  };

  useEffect(() => {
    if (orderItems.find((el) => el.id === orderItemSelected && el.quantity_added_to_order >= Number(el.quantity))) {
      setShowOverQuantityItemAlert(true);
      return;
    }
    setShowOverQuantityItemAlert(false);
  }, [orderItemSelected, orderItems]);

  return (
    <Container>
      <div>
        <Link
          to={() => {
            if (searchQuery === null) {
              return `/order/${orderInfoData.id}?searchQuery=`;
            }
            return `/order/${orderInfoData.id}?searchQuery=${searchQuery}`;
          }}
        >
          <MUIButton variant="contained" color="primary" className={classes.button} startIcon={<KeyboardBackspace />}>
            Back
          </MUIButton>
        </Link>
      </div>
      <Title level={3}>{`Items for order #${orderNumber}`}</Title>

      <Table style={{ marginBottom: '20px' }} columns={columnsItems} data={orderItems} size="normal" rowKey="id" />
      {loading ? (
        <Spin tip="Loading...">
          <Alert message="Loading order data" description="Once all items are loaded, you will be able to add/remove items." type="info" />
        </Spin>
      ) : (
        <>
          <Divider />
          <Form
            form={form}
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 14,
            }}
            name="checklistForm"
            onFinish={debounce(onFinish, 700)}
            layout="horizontal"
            initialValues={{ size: 'small' }}
            size="small"
            style={{ width: 1100 }}
          >
            <Form.Item label="Order Item:" hasFeedback name="checklistOrderItem" rules={[{ required: true, message: 'Item must be selected' }]}>
              <Select
                onChange={(el) => setOrderItemSelected(el)}
                value={orderItemSelected}
                allowClear
                placeholder="Select an item"
                optionFilterProp="children"
                options={orderItemsOptions}
              />
            </Form.Item>
            <Form.Item
              label="Serial Number:"
              hasFeedback
              name="checklistSerialNumber"
              validateTrigger="onSubmit"
              rules={[{ required: !serialNumberStatus, message: 'Serial Number cannot be blank' }]}
            >
              <Input
                disabled={serialNumberStatus}
                allowClear
                autoComplete="off"
                placeholder="Serial Number"
                style={{ width: 200 }}
                ref={serialNumberRef}
                onBlur={(e) => {
                  if (e.target.value !== '') {
                    setPartNumberStatus(true);
                    // const serialNumberTrimmed = e.target.value.trim().toUpperCase();

                    // const serialNumberFormatted =
                    //   serialNumberTrimmed.substring(0, 4) === 'P/N:'
                    //     ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4)
                    //     : serialNumberTrimmed;
                    // form.setFieldsValue({ checklistSerialNumber: serialNumberFormatted });
                  } else {
                    setPartNumberStatus(false);
                  }
                }}
              />
            </Form.Item>

            {partNumberStatus === false && serialNumberStatus === true ? (
              <Space>
                <OrderAlert message={`This item has quantity ${partsQuantity}`} type="warning" showIcon />
              </Space>
            ) : (
              ''
            )}

            <Form.Item
              label="Inventory Item:"
              hasFeedback
              name="checklistInventoryItem"
              validateTrigger="onSubmit"
              rules={[{ required: !partNumberStatus, message: 'Part Number cannot be blank' }]}
            >
              <DebounceSelect
                ref={inventoryItemRef}
                showSearch
                allowClear
                style={{ width: 465 }}
                placeholder="Select an Item"
                optionFilterProp="label"
                fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                onClear={clearItem}
                onSelect={inventoryItemSelection}
                disabled={partNumberStatus}
              />
            </Form.Item>

            <Form.Item
              label="Inventory Quantity:"
              name="checklistInventoryQuantity"
              validateTrigger="onSubmit"
              rules={[{ required: !partNumberStatus, message: 'Quantity cannot be blank' }]}
            >
              <InputNumber min={1} max={100} placeholder="# items added" style={{ width: 200 }} disabled={partNumberStatus} />
            </Form.Item>

            {partNumberStatus === false && serialNumberStatus === true ? (
              <Space>
                {notesStatus === true ? (
                  <OrderAlert
                    message="Because this item doesn't have a work instruction, you must describe the testing process that was used."
                    type="warning"
                    showIcon
                  />
                ) : (
                  <OrderAlert message="By selecting this, you confirm that you read and followed the Work Instructions." type="warning" showIcon />
                )}
              </Space>
            ) : (
              ''
            )}

            <Form.Item
              label="Notes:"
              hasFeedback
              validateTrigger="onSubmit"
              name="checklistNotes"
              rules={[
                { required: notesStatus, message: 'Notes cannot be blank' },
                { min: 10, message: 'Notes must be minimum 10 characters.' },
              ]}
            >
              <TextArea ref={notesRef} placeholder="Add any additional notes related to this unit" rows={4} showCount />
            </Form.Item>

            {orderStatus === 'awaiting_shipment' || orderStatus === 'on_hold' || profile?.type === 999 ? (
              <>
                <MUIButton
                  disabled={showOverQuantityItemAlert || disableAddButtonWhenInProgress}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="small"
                >
                  Add
                </MUIButton>
                {showOverQuantityItemAlert && (
                  <Alert
                    message="You can no longer add more items than the number specified in each requested product. If the product requires multiple components to be scanned, you need to add more products to the order."
                    type="warning"
                  />
                )}
              </>
            ) : (
              <Tooltip title="This order was shipped and cannot be edited" color="orange">
                <span>
                  <MUIButton disabled type="submit" variant="contained" color="primary" className={classes.button} size="small">
                    Add
                  </MUIButton>
                </span>
              </Tooltip>
            )}
          </Form>
          <Divider />

          <Table columns={columnsChecklist} data={orderChecklistItems} size="small" rowKey="index" />
        </>
      )}
    </Container>
  );
}
