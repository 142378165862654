/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { SearchOutlined } from '@ant-design/icons';
import { Divider, Input, Space, Table, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';

import InputText from '~/components/InputText';
import api from '~/services/api';
import history from '~/services/history';

import InventorySummaryByLocation from '../Inventory/InventorySummaryByLocation';
import { Button, Container, TitleContainer } from './styles';

export default function InventoryBatteries({ location }) {
  const [inventoryList, setInventoryList] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  const [locationFilters, setLocationFilters] = useState(null);
  const [checkedLocations, setCheckedLocations] = useState(null);

  const { Paragraph, Title } = Typography;
  const searchInputRef = useRef(searchTxt);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleBatterySearch = async (value = '') => {
    setSearchTxt(value?.trim() || '');

    const params = new URLSearchParams();
    if (value) {
      params.append('searchQuery', encodeURIComponent(value.trim()));
    }

    if (checkedLocations) {
      params.append('locationFilters', checkedLocations);
    }

    history.replace(`inventoryBatteries?${params}`);

    const inventoryListData = await api.get(`inventory_batteries/getInventoryBatteryList?${params}`);
    if (!locationFilters) {
      setLocationFilters(() => Object.keys(inventoryListData.data.inventoryByLocation).filter((el) => el !== 'Total'));
    }
    setInventoryList(() => inventoryListData.data);
  };

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const searchQuery = params.get('searchQuery');
    searchInputRef.current.value = searchQuery;
    setSearchTxt(searchQuery);
    handleBatterySearch(searchQuery);
    searchInputRef.current.focus();
  }, []); //eslint-disable-line

  const columns = [
    {
      title: 'Brand',
      dataIndex: 'brand',
      sorter: (a, b) => a.brand.length - b.brand.length,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: 'Part Number',
      dataIndex: 'part_number',
      width: '10%',
      ...getColumnSearchProps('part_number'),
      sorter: (a, b) => a.part_number.length - b.part_number.length,
      render: (text, row) => (
        <Link
          to={() => {
            if (searchTxt === null || searchTxt === undefined) {
              return `inventoryBatteriesListByPN/${row.id}`;
            }
            // return `inventoryBatteriesListByPN/${row.id}?searchQuery=${searchTxt}&showZero=${zeroQty}`;
            return `inventoryBatteriesListByPN/${row.id}?searchQuery=${searchTxt}`;
          }}
        >
          <Paragraph style={{ color: '#1890ff' }} copyable>
            {text}
          </Paragraph>
        </Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: (a, b) => a.description.length - b.description.length,
      render: (text) => (
        <Paragraph style={{ maxWidth: '450px' }} copyable>
          {text}
        </Paragraph>
      ),
      width: 600,
    },
    {
      title: 'New',
      dataIndex: 'New',
      sorter: (a, b) => a.New - b.New,
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
    {
      title: 'TBI',
      dataIndex: 'TBI',
      sorter: (a, b) => a.TBI - b.TBI,
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
    {
      title: '100%',
      dataIndex: '100%',
      sorter: (a, b) => a['100%'] - b['100%'],
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
    {
      title: '80%-99%',
      dataIndex: '80%-99%',
      sorter: (a, b) => a['80%-99%'] - b['80%-99%'],
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
    {
      title: '60%-79%',
      dataIndex: '60%-79%',
      sorter: (a, b) => a['60%-79%'] - b['60%-79%'],
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
    {
      title: '<60%',
      dataIndex: '<60%',
      sorter: (a, b) => a['<60%'] - b['<60%'],
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      sorter: (a, b) => a.total - b.total,
      render: (text) => (text === 0 ? '' : text),
      align: 'center',
    },
  ];

  const handleSearchSerialNumberButton = () => {
    handleBatterySearch(searchInputRef.current.value);
  };

  const handleLocationFilterChange = useCallback(
    async (param) => {
      const params = new URLSearchParams();
      params.append('searchQuery', encodeURIComponent(searchTxt));
      params.append('locationFilters', param);

      const response = await api.get(`inventory_batteries/getInventoryBatteryList?${params}`);

      setInventoryList(() => response.data);
    },
    [searchTxt]
  );

  function downloadExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `InventoryBatteriesReport.xlsx`);
  }

  return (
    <Container>
      <Space direction="vertical">
        <TitleContainer>
          <Title level={3}>Battery Inventory</Title>
        </TitleContainer>
        <Space size="small">
          <InputText ref={searchInputRef} placeholder="Enter P/N or description" onPressEnter={handleBatterySearch} />
          <Button type="button" onClick={handleSearchSerialNumberButton}>
            <SearchOutlined />
          </Button>
          {inventoryList.inventoryByLocation && Object.keys(inventoryList.inventoryByLocation).length > 0 && (
            <InventorySummaryByLocation
              data={inventoryList.inventoryByLocation}
              setLocationFilters={setLocationFilters}
              setCheckedLocations={setCheckedLocations}
              checkedLocations={checkedLocations}
              handleSearch={handleSearch}
              handleLocationFilterChange={handleLocationFilterChange}
            />
          )}
        </Space>
        {/* <Switch checked={zeroQty} onChange={e => setZeroQty(e.target.checked)} name="zeroQty" color="primary" />
        Show items with zero qty */}
      </Space>
      <Divider />
      <Button type="primary" className="ant-dropdown-link" style={{ width: 200 }} onClick={() => downloadExcel(inventoryList.inventoryCount)}>
        Download page to Excel
      </Button>
      <Table
        style={{ marginTop: '2rem' }}
        columns={columns}
        dataSource={inventoryList.inventoryCount}
        // bordered
        // pagination={{
        //   // current: 1,
        //   pageSize: 15,
        // }}
        scroll={{ x: 1500, y: 'calc(100vh - 28em)' }}
        rowKey="id"
        size="small"
      />
    </Container>
  );
}
