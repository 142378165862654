import { Popover, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

import api from '~/services/api';
import { Container } from './styles';

const status = {
  Failed: <Tag color="error">Failed</Tag>,
  failed: <Tag color="error">Failed</Tag>,
  passed: <Tag color="success">Passed</Tag>,
  InStock: <Tag color="default">In Stock</Tag>,
  ToBeInspected: <Tag color="warning">To Be Inspected</Tag>,
  'ToBeInspected-RMA': <Tag color="warning">To Be Inspected-RMA</Tag>,
  Reserved: <Tag color="default">Reserved</Tag>,
  Allocated: <Tag color="default">Allocated</Tag>,
  InternalAsset: <Tag color="default">Internal Asset</Tag>,
  Returning: <Tag color="default">Returning</Tag>,
  excellent: <Tag color="success">Excellent</Tag>,
  good: <Tag color="default">Good</Tag>,
  fair: <Tag color="warning">Fair</Tag>,
  poor: <Tag color="warning">Poor</Tag>,
  major: <Tag color="error">Major</Tag>,
  moderate: <Tag color="warning">Moderate</Tag>,
  minor: <Tag color="default">Minor</Tag>,
  none: <Tag color="success">None</Tag>,
  'fixable-noCracks': <Tag color="success">Fixable/No Cracks</Tag>,
  unfixable: <Tag color="error">Unfixable</Tag>,
  // Cosmetic Screen
  scratch_on_protector: <Tag color="default">Scratch on Protector</Tag>,
  small_blemish: <Tag color="warning">Small Blemish</Tag>,
  'Small Blemish': <Tag color="warning">Small Blemish</Tag>,
  small_scratch: <Tag color="warning">Small Scratch</Tag>,
  blemish: <Tag color="warning">Blemish</Tag>,
  scratch: <Tag color="warning">Scratch</Tag>,
  marks_on_screen: <Tag color="warning">Marks on screen</Tag>,
  discoloration: <Tag color="default">Discoloration</Tag>,
  dead_pixel: <Tag color="warning">Dead pixel</Tag>,
  dim: <Tag color="warning">Dim</Tag>,
  flickering: <Tag color="warning">Flickering</Tag>,
  other: <Tag color="warning">Other</Tag>,
  'No Blemish': <Tag color="success">No Blemish</Tag>,
  no_symptoms: <Tag color="success">No Symptoms</Tag>,
};

const statusLabels = {
  Failed: 'Failed',
  InStock: 'In Stock',
  ToBeInspected: 'To Be Inspected',
  'ToBeInspected-RMA': 'To Be Inspected-RMA',
  Reserved: 'Reserved',
  Returning: 'Returning',
  Allocated: 'Allocated',
  InternalAsset: 'Internal Asset',
};

const gradeLabels = {
  A: 'A',
  New: 'New',
  B: 'B',
  C: 'C',
  D: 'D',
  FA: 'Failed',
  FP: 'For Parts',
  na: 'N/A',
  OB: 'Open Box',
  TBI: 'To be Inspected',
  U: 'Used',
};

export default function InventoryItemEdit({ location }) {
  //eslint-disable-line
  const [partNumber, setPartNumber] = useState('');
  // const [searchQuery, setSearchQuery] = useState('');
  // const [zeroQty, setZeroQty] = useState();

  const [statusFilter, setStatusFilter] = useState([]);
  const [gradeFilter, setGradeFilter] = useState([]);

  const [inventoryList, setInventoryList] = useState([]);
  const [cosmeticScreenList, setCosmeticScreenList] = useState({});

  const columns = [
    {
      title: <h2>{partNumber}</h2>,
      children: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          width: '3%',
          render: (_text, _record, index) => index + 1,
        },
        {
          title: 'Serial Number',
          dataIndex: 'serial_number',
          key: 'serial_number',
          render: (text, record) => (
            <a rel="noopener noreferrer" target="_blank" href={`/inventoryProcessing/${record.inventory_items_id}`}>
              {text}
            </a>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => a.status.localeCompare(b.status),
          sortDirections: ['descend', 'ascend'],
          render: (value) => status[value],
          filters: statusFilter,
          filterMode: 'tree',
          filterSearch: true,
          onFilter: (value, record) => record.status.startsWith(value),
        },
        {
          title: 'Condition',
          dataIndex: 'condition',
          key: 'condition',
        },
        {
          title: 'Grade',
          dataIndex: 'grade',
          key: 'grade',
          filters: gradeFilter,
          filterMode: 'tree',
          filterSearch: true,
          onFilter: (value, record) => record.grade.startsWith(value),
        },
        /**
        ad.cosmetics_label_condition,
        ad.cosmetics_missing_parts,
        ad.cosmetics_discoloration,
        ad.cosmetics_dents_scratches,
        ad.cosmetics_latches_hinges_damaged,
        ad.function_screen,
        ii.cosmetic_screen,
        ad.cosmetics_cracks,
         */
        {
          title: 'Label',
          dataIndex: 'cosmetics_label_condition',
          key: 'cosmetics_label_condition',
          render: (value) => status[value],
        },
        {
          title: 'Missing Parts',
          dataIndex: 'cosmetics_missing_parts',
          key: 'cosmetics_missing_parts',
          render: (value, record) => (record.condition !== 'New' ? value ? <Tag color="error">YES</Tag> : <Tag color="success">NO</Tag> : ''),
        },
        {
          title: 'Discoloration',
          dataIndex: 'cosmetics_discoloration',
          key: 'cosmetics_discoloration',
          render: (value) => status[value],
        },
        {
          title: 'Dents/Scratches',
          dataIndex: 'cosmetics_dents_scratches',
          key: 'cosmetics_dents_scratches',
          render: (value) => status[value],
        },
        {
          title: 'Damage on Latches/Hinges',
          dataIndex: 'cosmetics_latches_hinges_damaged',
          key: 'cosmetics_latches_hinges_damaged',
          render: (value, record) => (record.condition !== 'New' ? value ? <Tag color="error">YES</Tag> : <Tag color="success">NO</Tag> : ''),
          ellipsis: true,
        },
        {
          title: 'Screen',
          dataIndex: 'cosmetics_screen',
          key: 'cosmetics_screen',
          sorter: (a, b) => {
            if (a.cosmetic_screen === b.cosmetic_screen) return 0;
            if (a.cosmetic_screen === null) return 1;
            if (b.cosmetic_screen === null) return -1;
            return a.cosmetic_screen.localeCompare(b.cosmetic_screen, undefined, { numeric: true, sensitivity: 'base' });
          },
          sortDirections: ['descend', 'ascend'],
          render: (value, record) =>
            record.cosmetic_screen?.split(',').map((el) => (
              <Popover placement="top" content={status[value]} arrowPointAtCenter>
                {cosmeticScreenList[el]}
              </Popover>
            )),
        },
        {
          title: 'Cracks',
          dataIndex: 'cosmetics_cracks',
          key: 'cosmetics_cracks',
          render: (value) => status[value],
        },
        {
          title: 'Notes',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
        },
        {
          title: 'POST result',
          dataIndex: 'post_result',
          key: 'post_result',
          render: (value) => status[value],
        },
      ],
    },
  ];

  useEffect(() => {
    async function load() {
      const { search } = location;
      const params = new URLSearchParams(search);
      setPartNumber(params.get('partNumber'));
      // setSearchQuery(params.get('searchQuery'));
      // setZeroQty(params.get('zeroQty'));

      const inventoryListData = await api.get(`inventory_details/${params.get('partNumber')}`);
      const cosmeticScreenData = await api.get('cosmetic_screen_options');

      const cosmeticScreenSeverity = {};

      cosmeticScreenData.data.forEach((screen) => {
        const severityColor = screen.severity === 'low' ? 'success' : screen.severity === 'moderate' ? 'warning' : 'error';

        cosmeticScreenSeverity[screen.id] = <Tag color={severityColor}>{screen.name}</Tag>;
        cosmeticScreenSeverity.key = screen.id;
      });

      setInventoryList(inventoryListData.data);
      setCosmeticScreenList(cosmeticScreenSeverity);

      /** Status column filter */
      const statusFilterCount = inventoryListData.data.reduce((acc, cur) => {
        const key = cur.status;
        acc[key] = acc[key] || 0;
        acc[key]++;
        return acc;
      }, []);

      const statusKeys = Object.keys(statusFilterCount);

      const statusFilterLabel = [];
      statusKeys.forEach((el) => statusFilterLabel.push({ text: `${statusLabels[el]}(${statusFilterCount[el]})`, value: el }));
      setStatusFilter(statusFilterLabel);

      /** Grade column filter */
      const gradeFilterCount = inventoryListData.data.reduce((acc, cur) => {
        const key = cur.grade;
        acc[key] = acc[key] || 0;
        acc[key]++;
        return acc;
      }, []);

      const gradeKeys = Object.keys(gradeFilterCount);

      const gradeFilterLabel = [];
      gradeKeys.forEach((el) => gradeFilterLabel.push({ text: `${gradeLabels[el]}(${gradeFilterCount[el]})`, value: el }));
      setGradeFilter(gradeFilterLabel);
    }
    load();
  }, []); //eslint-disable-line

  return (
    <Container>
      {/* <div>
        <Link
          style={{ backgroundColor: 'red', display: 'inline-block' }}
          to={() => {
            if (searchQuery === null) {
              return `/inventory`;
            }
            return `/inventory?searchQuery=${searchQuery}&zeroQty=${zeroQty}`;
          }}
        >
          <Button type="primary" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </Link>
      </div> */}

      <Table
        style={{ marginTop: '15px' }}
        rowKey={(record) => record.serial_number}
        columns={columns}
        dataSource={inventoryList}
        pagination={{ defaultPageSize: 25, pageSizeOptions: [25, 50, 100], hideOnSinglePage: true, showSizeChanger: true }}
        size="small"
        scroll={{
          y: 'calc(100vh - 19em)',
        }}
        sticky
      />
    </Container>
  );
}
